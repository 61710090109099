import React from 'react';
import {
  ValidatedInput,
  ValidatedSelect,
  ValidatedFormContext,
} from 'components/common/validated-form';
import { BlueButton } from 'components/login/BlueButton';
import styled from 'styled-components';

export interface StyledRadioProps {
  label?: string;
  children?: React.ReactNode;
}

export interface StyledFormLabelProps
  extends React.ComponentProps<typeof StyledStyledFormLabel> {
  invert?: boolean;
}

export interface StyledStyledFormLabelProps {
  inline?: boolean;
}

export interface StyledValidatedButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  padding?: string;
}

export interface StyledValidatedSelectProps
  extends React.ComponentPropsWithoutRef<'select'> {
  className?: string;
}

export interface StyledValidatedInputProps
  extends React.ComponentPropsWithoutRef<'input'> {
  className?: string;
  validator?: RegExp | string | ((v: any) => boolean);
  keyPressFilter?: () => void;
}

export const StyledTextarea = styled.textarea`
  background: white;
  border-color: var(--COLOR_GALLERY);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  font-weight: normal;
  display: block;
  padding: 12px 15px 14px 15px;
  width: 100%;
  min-height: 3em;
  box-sizing: border-box;
`;

export const StyledRadio = (props: StyledRadioProps) => {
  return (
    <StyledStyledRadio>
      {props.label ? <h4>{props.label}</h4> : null}
      {props.children}
    </StyledStyledRadio>
  );
};

const StyledStyledRadio = styled.div`
  h4 {
    margin-top: 24px;
    margin-bottom: 18px;
  }

  label {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 22px;
    margin-right: 2em;
    input {
      margin-right: 1em;
      &:focus {
        outline: 0;
      }
    }
  }
`;

export const StyledFormLabel = ({
  invert,
  inline,
  ...props
}: StyledFormLabelProps) => {
  const labelClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLLabelElement>) => {
      const target = e.target as HTMLElement;
      if (
        ['input', 'select'].includes(target.tagName.toLowerCase()) &&
        (target as HTMLInputElement).type?.toLowerCase() !== 'checkbox'
      ) {
        e.preventDefault();
      }
    },
    [],
  );

  return (
    <StyledStyledFormLabel
      className={props.className || ''}
      onClick={labelClickHandler}
      inline={inline}
    >
      {invert ? props.children : null}
      <span className={`label-text ${props.className || ''}`}>
        {props.label}
        {props.optional || props.optionalLabel ? (
          <span className={`label-required ${props.className || ''}`}>
            {props.optionalLabel ? props.optionalLabel : 'optional'}
          </span>
        ) : null}
      </span>
      {props.hint ? (
        <span className={`hint-text ${props.className || ''}`}>
          {props.hint}
        </span>
      ) : null}
      {!invert ? props.children : null}
    </StyledStyledFormLabel>
  );
};

const StyledStyledFormLabel = styled.label<StyledStyledFormLabelProps>`
  display: ${({ inline }) => (inline ? 'flex' : 'block')};
  ${({ inline }) => (inline ? 'margin-bottom: 22px;' : '')}

  .label-text {
    display: block;
    ${({ inline }) => (inline ? 'flex: 1 1 auto;' : '')}
    font-size: 16px;
    margin-bottom: 11px;
    ${({ inline }) => (inline ? 'line-height: 1.5;' : '')}
  }

  .label-required {
    float: right;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    margin-top: 0.2em;
    color: var(--COLOR_HAVELOCK_BLUE);
  }

  .hint-text {
    display: block;
    margin: 0.5em 0;
    font-style: italic;
    font-size: 0.8em;
  }
`;

export const StyledValidatedSelect = React.forwardRef<
  HTMLSelectElement,
  StyledValidatedSelectProps
>((props, ref) => (
  <StyledFormInput className={props.className}>
    <ValidatedSelect {...props} ref={ref} />
  </StyledFormInput>
));
StyledValidatedSelect.displayName = 'StyledValidatedSelect';

export const StyledValidatedInput = React.forwardRef<
  HTMLInputElement,
  StyledValidatedInputProps
>((props, ref) => (
  <StyledFormInput className={props.className} data-type={props.type}>
    <ValidatedInput {...props} ref={ref} />
  </StyledFormInput>
));
StyledValidatedInput.displayName = 'StyledValidatedInput';

const StyledFormInput = styled.div`
  margin-bottom: 36px;
  &[data-type='checkbox'] {
    display: inline-block;
    input {
      display: inline-block;
      width: auto;
      margin-right: 12px;
      margin-top: 4px;
    }
  }
  input,
  select {
    background: white;
    border-color: var(--COLOR_GALLERY);
    box-shadow: 1px 1px 4px 0 rgba(74, 75, 83, 0.08);
    border-width: 1px;
    border-style: solid;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 16px;
    font-weight: bold;
    display: block;
    padding: 14px 15px 14px 14px;
    width: 100%;
    box-sizing: border-box;
  }

  input::placeholder,
  select::placeholder,
  select[data-show-placeholder] {
    color: color-mix(in srgb, var(--COLOR_BLACK) 80%, transparent);
    font-weight: normal;
    option {
      color: var(--COLOR_BLACK);
    }
  }

  select[data-show-placeholder] {
    background-color: color-mix(in srgb, var(--COLOR_BLACK) 10%, transparent);
  }

  input[data-invalid],
  select[data-invalid] {
    color: var(--COLOR_TUSCANY);
    border-color: var(--COLOR_TUSCANY);
    background-color: white;
    option {
      color: var(--COLOR_BLACK);
    }
  }

  select {
    padding: 11px 14px 13px 14px;
  }
`;

export const StyledValidatedButton = ({
  children,
  className,
  disabled,
  padding,
  ...props
}: StyledValidatedButtonProps) => {
  const { valid } = React.useContext(ValidatedFormContext);

  return (
    <div className={className}>
      <BlueButton
        {...props}
        disabled={!valid || disabled}
        padding={padding || '8px 48px'}
      >
        {children}
      </BlueButton>
    </div>
  );
};
