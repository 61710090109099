import React from 'react';
import getOr from 'lodash/fp/getOr';
import { AssetAccessContext } from 'components/asset-access';
import { LayoutContext } from 'components/layouts/LayoutContext';

export function useContentHover(closeOnEventHandler) {
  const [tooltipPosition, setTooltipPosition] = React.useState(null);
  const { hasCitatorAccess } = React.useContext(AssetAccessContext);
  const { raw } = React.useContext(LayoutContext);

  const onHover = React.useCallback(
    (e, caseId) => {
      if (!hasCitatorAccess || raw) return;
      if (e?.target) {
        const eventY = e.pageY;
        const elementTop = [...e?.target.getClientRects()].reduce(
          (top, rect) => {
            if (top === undefined) return rect.y;
            return rect.top <= eventY && rect.bottom >= eventY ? rect.y : top;
          },
          undefined,
        );
        const top = eventY < elementTop ? eventY : elementTop;
        setTooltipPosition({
          x: 0,
          y: 0,
          top: top - 10, // offset for arrow
          left: e.pageX,
          caseId,
        });
      } else setTooltipPosition(undefined);
    },
    [hasCitatorAccess, raw],
  );

  return [
    tooltipPosition,
    React.useCallback(
      event => {
        if (!event) return onHover();
        switch (event.target.tagName) {
          case 'A':
          case 'I':
          case 'B':
          case 'SPAN':
            const caseUrlId =
              getOr(null, 'dataset.caseUrlId')(event.target) ||
              getOr(null, 'parentNode.dataset.caseUrlId')(event.target) ||
              getOr(null, 'parentNode.parentNode.dataset.caseUrlId')(
                event.target,
              );

            if (caseUrlId) {
              onHover(event, caseUrlId);
            }
            break;
          default:
            onHover();
            break;
        }
      },
      [onHover],
    ),
    React.useCallback(
      event => {
        onHover();
        return closeOnEventHandler && closeOnEventHandler(event);
      },
      [onHover, closeOnEventHandler],
    ),
  ];
}
