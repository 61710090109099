import React from 'react';
import { AssetAccessContext } from 'components/asset-access';
import useChangeOptionFilters from 'hooks/useChangeOptionFilters';
import { useSearchParams } from 'react-router-dom';
import { OtherSectionsContextProvider } from 'components/common/search/OtherSectionContext';

import { caseSearchUrl } from 'util/caseUrl';
import uriStringify from 'util/uriStringify';
import { basePaths } from 'app-constants';

export const searchUrlFor = function(key, query) {
  switch (key) {
    case 'cases':
      return uriStringify(caseSearchUrl, query);
    case 'practitioner':
      return uriStringify(`${basePaths.practitioner}/search`, query);
    case 'publications':
      return uriStringify(`${basePaths.secondarySources}/search`, query);
    case 'statutes':
      return uriStringify(`${basePaths.statutes}/search`, query);
    case 'courtRules':
      return uriStringify(`${basePaths.courtRules}/search`, query);
    case 'news':
      return uriStringify(`${basePaths.news}/search`, query);
    case 'course':
      return uriStringify(`${basePaths.courses}/search`, query);
    default:
      return uriStringify(`${caseSearchUrl}`, query);
  }
};

export const SearchContext = React.createContext();

function useSearchVariables(queryString) {
  const {
    query,
    page = 1,
    sort = 'RELEVANCE',
    published,
    textFilters = '',
    optionFilters = '',
    caseDateGte,
    caseDateLte,
    yearSearch,
  } = queryString;

  const optionFiltersArray = React.useMemo(
    () => (optionFilters ? optionFilters.split(',') : []),
    [optionFilters],
  );

  return {
    query,
    page: page,
    sort: sort,
    published,
    textFilters: textFilters.split(','),
    optionFilters: optionFiltersArray,
    caseDateGte: yearSearch === 'custom' ? caseDateGte : yearSearch,
    caseDateLte:
      yearSearch === 'custom' ? caseDateLte : parseInt(yearSearch) + 1,
  };
}

export const SearchContextProvider = props => {
  const [searchParams] = useSearchParams();
  const queryString = React.useMemo(
    () => Object.fromEntries([...searchParams]),
    [searchParams],
  );
  const variables = useSearchVariables(queryString);

  const { hasPracticeAreas, hasSecondarySources } = React.useContext(
    AssetAccessContext,
  );

  const parsedOptionFilters = React.useMemo(
    () =>
      variables.optionFilters.reduce((filters, filter) => {
        filters[filter] = true;
        return filters;
      }, {}),
    [variables.optionFilters],
  );

  const changeOptionFilters = useChangeOptionFilters();

  return (
    <SearchContext.Provider
      value={{
        variables,
        queryString,
        changeOptionFilters,
        hasSecondarySources,
        hasPracticeAreas,
        optionFilters: parsedOptionFilters,
        searchUrlFor,
      }}
    >
      <OtherSectionsContextProvider>
        {props.children}
      </OtherSectionsContextProvider>
    </SearchContext.Provider>
  );
};
