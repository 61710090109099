import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

function styleBlueButton(component) {
  return styled(component)
    .withConfig({
      shouldForwardProp: prop =>
        ['to', 'children', 'disabled', 'type', 'onClick', 'state'].includes(
          prop,
        ),
    })
    .attrs(
      ({
        fullWidth,
        dark,
        outline,
        fontWeight = 'bold',
        fontSize = '18px',
        bgColor = 'COLOR_BLUE_RIBBON',
        padding,
      }) => {
        const backgroundColor = `var(--${dark ? 'COLOR_CEB_HEADER_BLUE' : bgColor})`;
        return {
          fullWidth,
          dark,
          outline,
          fontWeight,
          fontSize,
          bgColor,
          padding,
          backgroundColor,
        };
      },
    )`
    display: inline-block;
    box-sizing: border-box;

    background: ${({ outline, backgroundColor }) =>
      outline ? 'transparent' : backgroundColor};
    border: ${({ outline }) =>
      outline ? `solid 2px ${CEB_COLOR('BLUE_RIBBON')}` : 'none'};
    border-radius: 5px;
    color: ${({ outline }) => (outline ? CEB_COLOR('BLUE_RIBBON') : 'white')};
    font-family: 'BasisGrotesque', sans-serif;
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    line-height: 1.5;
    padding: ${({ padding }) => (padding ? padding : '13px 20px 10px')};
    text-align: center;
    text-decoration: none;
    ${({ fullWidth }) => (fullWidth ? 'width: 100%;max-width:none;' : '')}
    &:visited {
      color: ${({ outline }) => (outline ? CEB_COLOR('BLUE_RIBBON') : 'white')};
    }
    &[disabled] {
      cursor: default;
      ${({ outline }) =>
        outline
          ? `border: solid 2px ${CEB_COLOR('BLUEY_GRAY')}; color: ${CEB_COLOR(
              'BLUEY_GRAY',
            )};`
          : `background: ${CEB_COLOR('BLUEY_GRAY')}`}
    }
  `;
}

const button = props => <button {...props} />;
const StyledBlueButton = styleBlueButton(button);
const StyledLink = styleBlueButton(Link);

export function BlueButtonLink({
  dark = false,
  disabled = false,
  fontSize = '18px',
  fontWeight = 'bold',
  fullWidth = false,
  outline = false,
  padding,
  ...props
}) {
  const component = React.useMemo(
    () => (disabled ? StyledBlueButton : StyledLink),
    [disabled],
  );
  return React.createElement(component, {
    dark,
    disabled,
    fontSize,
    fontWeight,
    fullWidth,
    outline,
    padding,
    ...props,
  });
}

export function BlueButton({
  dark = false,
  disabled = false,
  fontSize = '18px',
  fontWeight = 'bold',
  fullWidth = false,
  funBlue = false,
  outline = false,
  padding,
  ...props
}) {
  return (
    <StyledBlueButton
      dark={dark}
      disabled={disabled}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fullWidth={fullWidth}
      outline={outline}
      padding={padding}
      bgColor={funBlue ? 'COLOR_NICE_BLUE' : 'COLOR_BLUE_RIBBON'}
      {...props}
    />
  );
}

export function Button({
  bgColor,
  className,
  dark,
  disabled = false,
  fontSize = '18px',
  fontWeight = 'bold',
  fullWidth = false,
  outline = false,
  padding,
  ...props
}) {
  return (
    <StyledBlueButton
      dark={dark}
      bgColor={bgColor}
      disabled={disabled}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fullWidth={fullWidth}
      outline={outline}
      padding={padding}
      className={className}
      {...props}
    />
  );
}
