import React, { useMemo } from 'react';

import serialize from 'form-serialize';

import flow from 'lodash/fp/flow';
import values from 'lodash/fp/values';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';
import MaskedInput from 'react-maskedinput';
import omit from 'lodash/fp/omit';

export const ValidatedFormContext = React.createContext();

const isValid = flow(
  values,
  map(value => value.valid),
  filter(v => v !== true),
  isEmpty,
);

function formStateReducer(state, action) {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, [action.name]: { valid: action.valid } };
    case 'REMOVE':
      return omit(action.name)(state);
    default:
  }
  return state;
}

export const ValidatedForm = ({
  onChange = () => {},
  onSubmit = () => {},
  onValidChange = () => {},
  ...props
}) => {
  const [state, dispatch] = React.useReducer(formStateReducer, {});
  const [valid, setValid] = React.useState();

  const validatedFormRef = React.useRef();

  const setValidity = React.useCallback(
    (name, valid) => {
      dispatch({ type: 'UPDATE', name, valid });
    },
    [dispatch],
  );

  const [hideValidity, setHideValidity] = React.useState(true);

  const removeField = React.useCallback(
    name => {
      dispatch({ type: 'REMOVE', name });
    },
    [dispatch],
  );

  React.useEffect(() => {
    setValid(isValid(state));
  }, [state]);

  React.useEffect(() => {
    onValidChange && onValidChange(valid);
  }, [valid, onValidChange]);

  const submit = React.useCallback(event => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit &&
      onSubmit(serialize(event.currentTarget, { hash: true, disabled: true }));
  });

  const change = React.useCallback(
    event => {
      setHideValidity(false);

      onChange &&
        onChange(
          serialize(event.currentTarget, { hash: true, disabled: true }),
        );
    },
    [setHideValidity, onChange],
  );

  const reset = React.useCallback(event => {
    setTimeout(() => {
      [...validatedFormRef.current.querySelectorAll('input')].forEach(el => {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('input', true, false);
        el.dispatchEvent(event);
        setHideValidity(true);
      });
    }, 10);
  });

  const value = useMemo(
    () => ({
      valid,
      setValidity,
      setHideValidity,
      removeField,
      hideValidity,
    }),
    [valid, setValidity, setHideValidity, removeField, hideValidity],
  );

  return (
    <ValidatedFormContext.Provider value={value}>
      <form
        onSubmit={submit}
        onChange={change}
        onReset={reset}
        ref={validatedFormRef}
        {...props}
      >
        {props.children}
      </form>
    </ValidatedFormContext.Provider>
  );
};

function validate(value, validator, event) {
  return validator
    ? typeof validator === 'function'
      ? validator(value, event)
      : validator.test(value)
    : value
      ? true
      : false;
}

export const Validated = React.forwardRef(
  (
    {
      element,
      onChange,
      validator,
      required,
      name,
      validateOn,
      keyPressFilter,
      onKeyPress,
      onKeyDown,
      ...props
    },
    ref,
  ) => {
    const context = React.useContext(ValidatedFormContext);

    const [value, setValue] = React.useState(props.value || props.defaultValue);

    const [valid, setValid] = React.useState(
      required ? validate(props.value || props.defaultValue) : true,
    );

    const validateIfRequired = React.useMemo(() => {
      return event => {
        const {
          target: { value },
        } = event;

        setValid(
          !required && !value ? true : validate(value, validator, event),
        );
      };
    }, [required, setValid, validator]);

    const change = React.useCallback(
      event => {
        const {
          target: { value },
        } = event;

        validateIfRequired(event);
        setValue(value);
        onChange && onChange(event);
      },
      [onChange, setValue, validateIfRequired],
    );

    const onClick = React.useCallback(
      event => {
        if (context && context.setHideValidity) {
          context.setHideValidity(false);
        }
        validateIfRequired(event);
        props.onClick && props.onClick(event);
      },
      [props.onClick, validateIfRequired, context],
    );

    const onBlur = React.useCallback(
      e => {
        validateIfRequired(e);
        props.onBlur && props.onBlur(e);
      },
      [change, props.onBlur],
    );

    React.useEffect(() => {
      if (context) {
        context.setValidity(name, valid);
      }
    }, [valid, context, name]);

    const keyPress = React.useCallback(
      event => {
        if (keyPressFilter && !keyPressFilter(event.charCode)) {
          event.preventDefault();
          return false;
        }
        if (onKeyPress) return onKeyPress(event);
        return true;
      },
      [keyPressFilter, onKeyPress],
    );

    const handleKeyDown = React.useCallback(
      event => {
        if (onKeyDown) return onKeyDown(event);
        return true;
      },
      [onKeyDown],
    );

    React.useEffect(() => {
      if (props.value) {
        setValue(props.value);
        const event = {
          target: {
            value: props.value,
          },
        };
        validateIfRequired(event);
      }
    }, [props.value]);

    React.useEffect(() => {
      //remove field from validated state
      return () => {
        context?.removeField(name);
      };
    }, [context, name]);

    return React.createElement(
      element,
      {
        ...props,
        ...(valid || context.hideValidity ? {} : { 'data-invalid': true }),
        name,
        required,
        ...(element === 'select' && !value
          ? { 'data-show-placeholder': true }
          : {}),
        onClick: onClick,
        onChange: change,
        onInput: change,
        onBlur: onBlur,
        onKeyPress: keyPress,
        onKeyDown: handleKeyDown,
        ref,
      },
      props.children,
    );
  },
);
Validated.displayName = 'Validated';

export const ValidatedInput = React.forwardRef((props, ref) => (
  <Validated
    element={props.mask ? MaskedInput : 'input'}
    ref={ref}
    {...props}
  />
));
ValidatedInput.displayName = 'ValidatedInput';

export const ValidatedSelect = React.forwardRef(
  ({ placeholder, ...props }, ref) => {
    return (
      <Validated element={'select'} {...props} ref={ref}>
        {placeholder && (
          <option hidden={props.required} value="">
            {placeholder}
          </option>
        )}
        {props.children}
      </Validated>
    );
  },
);
ValidatedSelect.displayName = 'ValidatedSelect';

export const ValidatedButton = ({ children, disabled, ...props }) => {
  const { valid } = React.useContext(ValidatedFormContext);

  return (
    <button disabled={!valid || disabled} {...props}>
      {children}
    </button>
  );
};
